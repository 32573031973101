<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />
    <div class="page">
      <img src="@/assets/images/icon-check-circle.svg" />
      <div class="mt-3 font-weight-bold">預約已成功送出</div>
    </div>
  </div>
</div>
</template>

<script>
import { themeColorFn } from "@/mixins/liff/themeColor";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import { mapGetters } from 'vuex';

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'booking' })
  ],
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
  methods: {
    getModuleConfig: generateModuleConfigGetter('liff_booking'),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}
</style>
